body{
    overflow:hidden;
    background-color: #eee;
}
.app{
    height:var(--winHeight);
    width:var(--winWidth);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.boss{
    height:60%;
    width:95%;
    text-align: center;
    line-height: 100%;
}
.boss img{
    max-width: 100%;
    max-height:100%;
    height:100%;
    width:auto;
}
.dialogue{
    font-size:32px;
    border: 8px solid #111;
    top:60%;
    height:35%;
    width:95%;
    line-height: 2em;
    margin-left: 32px;
    margin-right:32px;
    color: #eee;
    background-color: rgb(40,40,40);
}
.DialogBox{
    padding:32px;
}

.dialogue ul{
    margin:0;
    width: 95%;
    height: 100%;
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;                                         
}
.dialogue ul li {
    padding-top: 1em;
    padding-bottom: 1em;
}

.loadingLightbox{
    position:fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background-color: black;
    color:white;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.settings{
    position:fixed;
    top:0;
    right:10px;
    z-index: 100;
    color:gray;
}